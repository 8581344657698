<div id="header" class="max-lg:h-[20px]">
  <div [ngClass]="showScroll ? 'header-up' : 'header-down'" class=" bg-colorActive py-[15px] w-full z-[9999] fixed top-0 max-lg:py-[0px]">
    <div class="flex items-center justify-between lg:container lg:mx-auto lg:px-[15px]
     max-lg:justify-end">
      <div class="header-logo max-lg:absolute
      max-lg:right-[50%] max-lg:translate-x-[50%]
      ">
        <img
        ngSrc="assets/images/logo_header.png"
          alt="Govo Vietnam"
          title="Govo Vietnam"
          width="244"
          height="50"
          [routerLink]="['']"
          class="cursor-pointer max-lg:h-[18px] max-lg:w-[98px]"
        />
      </div>
      <div class="max-lg:hidden">
        <nav id="nav" >
          <ul id="navigation" class="flex items-center justify-center gap-x-[56px]">
            @for (item of menu; track $index) {
              <li class="nav-item">
                <button
                  class="leading-[44px] text-white text-lg font-medium cursor-pointer"
                  (click)="scrollToSection(item.link)"
                  >{{ item.title }}
                </button>
              </li>
            }
          </ul>
        </nav>
      </div>
      <div class="hidden max-lg:block">
        <div class="cursor-pointer w-[40px] h-[40px] flex items-center justify-center" (click)="this.openMenuMobile = !this.openMenuMobile"
            ><img
                src="assets/images/menu.svg"
                alt="Facebook Govo"
                title="Facebook Govo"
        /></div>
      </div>
    </div>
    <div class="relative z-[9999]" *ngIf="this.openMenuMobile">
        <ul class="absolute w-full bg-[#181818] flex justify-center flex-col items-center gap-y-6 py-6">
            @for (item of menu; track $index) {
                <li class="nav-item">
                  <a
                    class="leading-[24px] text-white text-[16px] font-medium cursor-pointer"
                    (click)="scrollToSection(item.link)"
                    >{{ item.title }}
                  </a>
                </li>
              }
        </ul>
      </div>
  </div>

</div>
